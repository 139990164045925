import $ from "jquery";
import appConfig from "@/app.config";
import RetailModal from "./RetailerModal";
import Storage from "../../../services/storage";
var storage = new Storage();
export default {
  name: "account-settings",
  page: {
    meta: [{
      name: "description",
      content: appConfig.description
    }]
  },
  components: {
    RetailModal
  },
  data() {
    return {};
  },
  methods: {
    openModal() {
      let element = this.$refs.retailModal.$el;
      $(element).modal("show");
    }
  },
  mounted() {
    // Analytics
  },
  computed: {
    userFrancophone() {
      const user = storage.getSavedState("currentUser");
      return user.country === "CI" || user.country === "SN";
    }
  }
};