var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "mnotify-card",
    attrs: {
      "id": "account-settings"
    }
  }, [_c('div', {
    staticClass: "mnotify-card-header",
    attrs: {
      "id": "side-indicators-content"
    }
  }, [_c('h3', {
    staticClass: "mnotify-card-header-text py-0 my-0"
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.account_settings.header")) + " ")])]), _c('div', {
    staticClass: "mnotify-card-body"
  }, [_c('div', {
    staticClass: "billing-card-inner"
  }, [_c('ul', [_c('li', [_c('router-link', {
    attrs: {
      "to": "/change-password"
    }
  }, [_c('div', [_c('h3', {
    staticClass: "header"
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.account_settings.change_password.title")) + " "), _c('span', {
    staticStyle: {
      "float": "right"
    }
  }, [_c('img', {
    attrs: {
      "height": "20",
      "src": require("@/assets/right-arrow (1).svg"),
      "alt": ""
    }
  })])]), _c('p', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.account_settings.change_password.sub_header")) + " ")])])])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/change-phone-number"
    }
  }, [_c('div', [_c('h3', {
    staticClass: "header"
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.account_settings.change_number.title")) + " "), _c('span', {
    staticStyle: {
      "float": "right"
    }
  }, [_c('img', {
    attrs: {
      "height": "20",
      "src": require("@/assets/right-arrow (1).svg"),
      "alt": ""
    }
  })])]), _c('p', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.account_settings.change_number.sub_text")) + " ")])])])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/change-email"
    }
  }, [_c('div', [_c('h3', {
    staticClass: "header"
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.account_settings.change_email.title")) + " "), _c('span', {
    staticStyle: {
      "float": "right"
    }
  }, [_c('img', {
    attrs: {
      "height": "20",
      "src": require("@/assets/right-arrow (1).svg"),
      "alt": ""
    }
  })])]), _c('p', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.account_settings.change_email.sub_header")) + " ")])])])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/email-and-notification"
    }
  }, [_c('div', [_c('h3', {
    staticClass: "header"
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.account_settings.sms_wallet_notification.title")) + " "), _c('span', {
    staticStyle: {
      "float": "right"
    }
  }, [_c('img', {
    attrs: {
      "height": "20",
      "src": require("@/assets/right-arrow (1).svg"),
      "alt": ""
    }
  })])]), _c('p', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.account_settings.sms_wallet_notification.sub_header")) + " ")])])])], 1), !_vm.userFrancophone ? _c('li', [_c('router-link', {
    attrs: {
      "to": "/referral-settings"
    }
  }, [_c('div', [_c('h3', {
    staticClass: "header"
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.account_settings.referral_code.title"))), _c('span', {
    staticStyle: {
      "float": "right"
    }
  }, [_c('img', {
    attrs: {
      "height": "20",
      "src": require("@/assets/right-arrow (1).svg"),
      "alt": ""
    }
  })])]), _c('p', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.account_settings.referral_code.sub_header")) + " ")])])])], 1) : _vm._e(), _c('li', [_c('router-link', {
    attrs: {
      "to": "/default-account-settings"
    }
  }, [_c('div', [_c('h3', {
    staticClass: "header"
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.account_settings.account_defaults.defaults")) + " "), _c('span', {
    staticStyle: {
      "float": "right"
    }
  }, [_c('img', {
    attrs: {
      "height": "20",
      "src": require("@/assets/right-arrow (1).svg"),
      "alt": ""
    }
  })])]), _c('p', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.account_settings.account_defaults.change_defaults")) + " ")])])])], 1)])]), _c('div', {
    attrs: {
      "id": "become_a_retailer"
    }
  }, [_c('div', {
    staticClass: "_retailer_text"
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.account_settings.reseller.earn")) + " ")]), _c('div', {
    staticClass: "_retailer_btn"
  }, [_c('button', {
    staticClass: "become-a-reseller",
    on: {
      "click": _vm.openModal
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.account_settings.reseller.become_reseller")) + " ")])])])])]), _c('RetailModal', {
    ref: "retailModal"
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };