var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "modal fade",
    attrs: {
      "id": "senderID",
      "tabindex": "-1",
      "role": "dialog",
      "aria-labelledby": "contact",
      "aria-hidden": "true"
    }
  }, [_c('div', {
    staticClass: "modal-dialog modal-dialog-centered",
    attrs: {
      "role": "document"
    }
  }, [_vm.success ? _c('div', {
    staticClass: "modal-content d-flex align-items-center"
  }, [_c('successMessage', {
    attrs: {
      "message": _vm.success
    }
  }), _c('button', {
    staticClass: "__close__success__modal",
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("misc.button.close")) + " ")])], 1) : _vm._e(), !_vm.success && _vm.senderModalState.type == 'delete' ? _c('div', {
    staticClass: "modal-content"
  }, [_c('div', {
    staticClass: "modal-body m-0 p-0"
  }, [_c('div', {
    staticClass: "row justify-content-center align-items-center mx-4"
  }, [_c('div', {
    staticClass: "continue_edit_header pb-2"
  }, [_vm._m(0), _c('h3', {
    staticClass: "continue_edit_header_title"
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.sender_id.delete")) + " ")])])]), _vm.errors ? _c('div', {
    staticStyle: {
      "margin-left": "5em",
      "margin-right": "4em"
    }
  }, [_c('Alert', {
    attrs: {
      "message": _vm.errors,
      "type": _vm.type
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "modal__footer__btns"
  }, [_c('el-button', {
    staticStyle: {
      "margin-right": "1.5em",
      "padding-right": "2em",
      "padding-left": "2em"
    },
    attrs: {
      "loading": _vm.loading,
      "type": "danger",
      "tabindex": "0"
    },
    on: {
      "click": _vm.deleteSenderID
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("misc.button.delete")) + " ")]), _c('el-button', {
    staticClass: "notranslate",
    staticStyle: {
      "margin-right": "1.5em",
      "padding-right": "2em",
      "padding-left": "2em"
    },
    attrs: {
      "type": "primary",
      "tabindex": "1"
    },
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("misc.button.cancel")) + " ")])], 1)])]) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.success && _vm.senderModalState.type == 'newSenderID',
      expression: "!success && senderModalState.type == 'newSenderID'"
    }],
    staticClass: "modal-content"
  }, [_c('div', {
    staticClass: "modal-header pt-4 pb-2"
  }, [_c('h5', {
    staticClass: "modal-title",
    attrs: {
      "id": "exampleModalLabel"
    }
  }, [_vm._v(" " + _vm._s(_vm.senderModalState.title) + " ")])]), _c('div', {
    staticClass: "modal-body m-0 p-0"
  }, [_c('div', {
    staticClass: "row justify-content-center",
    staticStyle: {
      "padding-right": "1em",
      "padding-left": "1em"
    }
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('el-form', {
    ref: "ruleForm",
    attrs: {
      "model": _vm.formData,
      "rules": _vm.rules
    },
    nativeOn: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.checkEnter.apply(null, arguments);
      },
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.submit('ruleForm');
      }
    }
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form_input_container"
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.$t('dashboard.sender_id.label'),
      "prop": "sender_id"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text",
      "autocomplete": "off",
      "placeholder": _vm.$t('dashboard.sender_id.placeholder')
    },
    model: {
      value: _vm.formData.sender_id,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "sender_id", $$v);
      },
      expression: "formData.sender_id"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form_input_container"
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.$t('dashboard.sender_id.purpose'),
      "prop": "purpose"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 7,
      "autocomplete": "off",
      "placeholder": _vm.$t('dashboard.sender_id.placeholder_purpose')
    },
    model: {
      value: _vm.formData.purpose,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "purpose", $$v);
      },
      expression: "formData.purpose"
    }
  })], 1)], 1), _c('div', {
    staticClass: "__sender_id_text"
  }, [_c('i18n', {
    attrs: {
      "path": "dashboard.sender_id.via_api",
      "for": "dashboard.sender_id.here"
    },
    scopedSlots: _vm._u([{
      key: "here",
      fn: function () {
        return [_c('a', {
          attrs: {
            "href": "https://readthedocs.mnotify.com/#tag/Campaign/operation/sender_id/get",
            "target": "__blank"
          }
        }, [_vm._v(_vm._s(_vm.$t("dashboard.sender_id.here")))])];
      },
      proxy: true
    }])
  })], 1)])]), _vm.errors ? _c('span', [_c('Alert', {
    attrs: {
      "message": _vm.errors,
      "type": _vm.type
    }
  })], 1) : _vm._e()])], 1)]), _vm.senderModalState.type == 'newSenderID' ? _c('div', {
    staticClass: "modal__footer__btns"
  }, [_c('button', {
    staticClass: "cancel notranslate",
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("misc.button.cancel")) + " ")]), _c('el-button', {
    attrs: {
      "loading": _vm.loading,
      "type": "primary",
      "id": "__create__btn"
    },
    on: {
      "click": function ($event) {
        return _vm.submit('ruleForm');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/3 User.svg"),
      "alt": "user profile icon"
    }
  }), _vm._v("  " + _vm._s(_vm.senderModalState.btnTitle) + " ")])], 1) : _vm._e()])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "_edit_icon text-center"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/Delete.svg"),
      "height": "33px",
      "width": "31px",
      "alt": "bin icon for delete"
    }
  })]);
}];
export { render, staticRenderFns };