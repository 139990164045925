import store from "@/state/store.js";
let $ = require("jquery");
export default {
  name: "create-new-group",
  props: {
    userData: {
      required: false
    }
  },
  data() {
    return {
      success: "",
      errors: "",
      loading: false,
      type: "alert-danger",
      formData: {
        sender_id: "",
        purpose: ""
      },
      rules: {
        sender_id: [{
          required: true,
          message: this.$t("misc.form.required"),
          trigger: "change"
        }, {
          max: 11,
          message: this.$t("dashboard.sender_id.min"),
          trigger: ["change", "blur"]
        }],
        purpose: [{
          required: true,
          message: this.$t("dashboard.sender_id.purpose_sender_id"),
          trigger: "change"
        }]
      }
    };
  },
  mounted() {
    // store.dispatch('senderId/setSenderModalState')
  },
  computed: {
    senderModalState() {
      return store.getters["senderid/senderModalState"];
    },
    groups() {
      return store.getters["groups/all_groups"];
    }
  },
  methods: {
    hideModal() {
      this.$emit("hideModal");
      this.success = "";
      this.loading = false;
      this.errors = false;
    },
    deleteSenderID() {
      this.errors = "";
      this.loading = true;
      this.success = "";
      store.dispatch("senderid/deleteSenderID", this.userData).then(response => {
        this.loading = false;
        this.success = response.data.message, this.formData = {
          sender_id: "",
          purpose: ""
        };
      }).catch(err => {
        var _err$response;
        this.loading = false;
        if (((_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.data) == undefined) {
          this.errors = "Network error try again";
        }
        if (err.response && err.response.status === 404) {
          this.errors = err.response.data.data.error;
        }
      });
    },
    submit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.errors = "";
          this.loading = true;
          store.dispatch("senderid/registerSenderID", this.formData).then(response => {
            this.loading = false;
            this.success = response.data.message, this.formData = {
              sender_id: "",
              purpose: ""
            };
          }).catch(err => {
            var _err$response2;
            this.loading = false;
            if (((_err$response2 = err.response) === null || _err$response2 === void 0 ? void 0 : _err$response2.data) == undefined) {
              this.errors = "Network error try again";
            }
            if (err.response && err.response.status === 404) {
              if (err.response.data.data.sender_id) {
                return this.errors = err.response.data.data.sender_id[0];
              }
              this.errors = err.response.data.data.error;
            }
          });
        } else {
          return false;
        }
      });
    }
  }
};