import Storage from "@/services/storage.js";
import SenderIDModal from "../../dashboard/main/senderIDModal.vue";
import store from "@/state/store.js";
import $ from "jquery";
export default {
  components: {
    SenderIDModal: SenderIDModal
  },
  data() {
    return {
      success: "",
      errors: [],
      loading: false,
      type: "alert-danger",
      fileError: "",
      logo: "",
      formData: {
        domain: "",
        logo: {},
        phone: "",
        api_key: "",
        company_name: "",
        sender_id: ""
      },
      rules: {
        phone: [{
          required: true,
          message: "Phone number is required.",
          trigger: "blur"
        }, {
          pattern: /^[0-9]{10}$/g,
          message: "Phone number must be 10 digits"
        }],
        company_name: [{
          required: true,
          message: "Company Name is required.",
          trigger: "change"
        }],
        domain: [{
          required: true,
          message: "Domain is required.",
          trigger: "change"
        }],
        sender_id: [{
          required: true,
          message: "Sender ID is required.",
          trigger: "change"
        }],
        api_key: [{
          required: true,
          message: "API key is required.",
          trigger: "change"
        }]
      }
    };
  },
  computed: {
    senderIDs() {
      return store.getters["senderid/senderIDs"];
    },
    corporate() {
      const storage = new Storage();
      return storage.getSavedState("isCorporate");
    }
  },
  methods: {
    handleFileExceed() {
      this.$message.error("You can only upload one file");
    },
    handleChange(file, fileList) {
      this.formData.logo = file.raw;
      this.logo = file.raw;
    },
    hideModal() {
      $("#retailModal").modal("hide");
      this.error = "";
      this.success = "";
      this.loading = false;
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = "";
        if (key == "logo") {
          this.formData[key] = {};
        }
      });
    },
    hideSenderIDModal() {
      let element = this.$refs.senderID.$el;
      $(element).modal("hide");
    },
    submitAction(formName) {
      this.errors = [];
      this.loading = true;
      this.$refs[formName].validate(valid => {
        if (valid) {
          const formData = new FormData();
          const {
            domain,
            logo,
            api_key,
            phone,
            company_name,
            sender_id
          } = this.formData;
          formData.append("api_key", api_key);
          formData.append("domain", domain);
          formData.append("logo", logo);
          formData.append("phone", phone);
          formData.append("company_name", company_name);
          formData.append("sender_id", sender_id);
          return store.dispatch("auth/createRetailerAccount", formData).then(message => {
            this.success = message;
          }).catch(err => {
            var _err$response;
            if ((err === null || err === void 0 || (_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.data) == undefined) {
              this.errors = "Network error try again.";
            }
            if (err.response && err.response.status === 404) {
              this.errors = Object.values(err.response.data.data);
            }
          }).finally(() => this.loading = false);
        } else {
          this.loading = false;
          return false;
        }
      });
    },
    showSenderIDModal() {
      store.dispatch("senderid/setSenderModalState", {
        title: "Register a new Sender ID",
        type: "newSenderID",
        btnTitle: "Register new Send ID"
      });
      let element = this.$refs.senderID.$el;
      $(element).modal("show");
    }
  },
  mounted() {
    this.error = "";
    this.success = "";
    this.loading = false;
    this.formData = {
      api_key: "",
      sender_id: "",
      api_key: "",
      domain: "",
      logo: "",
      company_name: ""
    };
    //   store.dispatch('admin/massMessage/getIndustries').then( data => console.info(data))
  }
};